import React from "react"
import { graphql } from "gatsby"
import { FormattedMessage, useIntl } from "react-intl"
import { withDefault, useQueryParam, StringParam } from "use-query-params"
import { useFlexSearch } from "react-use-flexsearch"

import Layout from "../layouts"

import LocalizedLink from "../components/LocalizedLink"
import SearchBar from "../components/SearchBar"
import SearchResult, {
  SEARCH_RESULT_TYPE_PAGE,
  SEARCH_RESULT_TYPE_POST,
  SEARCH_RESULT_TYPE_PRODUCT,
} from "../components/SearchResult"

const SearchPage = ({
  data: {
    localSearchPages: { index: pageIndex, store: pageStore },
    localSearchPosts: { index: postIndex, store: postStore },
    localSearchProducts: { index: productIndex, store: productStore },
    navMenus: menus,
  },
  pageContext: { locale },
}) => {
  // hooks
  const [searchQuery, setSearchQuery] = useQueryParam(
    "s",
    withDefault(StringParam, "")
  )
  const { formatMessage } = useIntl()

  const pageResults = useFlexSearch(searchQuery, pageIndex, pageStore)
  const postResults = useFlexSearch(searchQuery, postIndex, postStore)
  const productResults = useFlexSearch(searchQuery, productIndex, productStore)
  const resultsCounter = pageResults.length + postResults.length + productResults.length

  // extract localized menu collections
  const localizedMenuCollections = Object.values(menus.nodes)
    .flat()
    .filter(menu => menu.node_locale === locale)
    .flat()
    .map(menu => menu.menuEntries)
    .flat()
    .filter(entry => typeof entry.slug !== "undefined")

  return (
    <Layout title={formatMessage({ id: "pages.search.title" })}>
      <section className="section has-background-paper">
        <div className="container">
          <h1 className="title mt-6">
            <FormattedMessage id="pages.search.title" />
            {resultsCounter > 0 && (
              <React.Fragment>
                :{" "}
                <FormattedMessage
                  id={"general.search.results"}
                  values={{ counter: resultsCounter }}
                />
              </React.Fragment>
            )}
          </h1>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <p>
                <FormattedMessage id="general.search.description" />
              </p>
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
          </div>
        </div>
      </section>

      {/* No results */}
      {resultsCounter === 0 && searchQuery !== "" && (
        <section className="section" id="content">
          <div className="container">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <p>
                  <FormattedMessage id="general.search.no-results" />
                </p>
                <p>
                  {localizedMenuCollections.map(collection => (
                    <LocalizedLink
                      key={collection.slug}
                      to={`/${collection.slug}`}
                      className="button is-primary mr-2"
                    >
                      {collection.title}
                    </LocalizedLink>
                  ))}
                </p>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Results found */}
      {resultsCounter > 0 && (
        <section className="section" id="content">
          <div className="container">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <ul>
                  {productResults.map((result, index) => (
                    <li key={`product-${index}`}>
                      <SearchResult
                        result={result}
                        locale={locale}
                        type={SEARCH_RESULT_TYPE_PRODUCT}
                      />
                    </li>
                  ))}
                  {postResults.map((result, index) => (
                    <li key={`post-${index}`}>
                      <SearchResult
                        result={result}
                        locale={locale}
                        type={SEARCH_RESULT_TYPE_POST}
                      />
                    </li>
                  ))}
                  {pageResults.map((result, index) => (
                    <li key={`page-${index}`}>
                      <SearchResult
                        result={result}
                        locale={locale}
                        type={SEARCH_RESULT_TYPE_PAGE}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* <pre>{JSON.stringify(productResults, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(postResults, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(pageResults, null, 2)}</pre> */}
    </Layout>
  )
}

export default SearchPage

export const SearchPageQuery = graphql`
  query {
    localSearchPages {
      index
      store
    }
    localSearchPosts {
      index
      store
    }
    localSearchProducts {
      index
      store
    }
    navMenus: allContentfulMenu(
      filter: { menuSlug: { eq: "main-navigation" } }
    ) {
      nodes {
        node_locale
        menuSlug
        menuEntries {
          ... on ContentfulCollection {
            id
            internal {
              type
            }
            slug: collectionSlug
            title: collectionTitle
            contentful_id
            shopifyCollectionStorefrontId
          }
        }
      }
    }
  }
`
