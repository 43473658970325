import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import LocalizedLink from "./LocalizedLink"
import { FormattedMessage } from "react-intl"

export const SEARCH_RESULT_TYPE_PAGE = "page"
export const SEARCH_RESULT_TYPE_POST = "post"
export const SEARCH_RESULT_TYPE_PRODUCT = "product"
export const SEARCH_RESULT_EXCERPT_LENGTH = 200

const SearchResultPage = ({ result, locale }) => {
  return (
    <div className="columns is-mobile mb-4">
      <div className="column is-one-quarter"></div>
      <div className="column">
        <h2 className="subtitle">
          <LocalizedLink
            to={`/${result[`slug-${locale}`]}`}
            alt={result[`title-${locale}`]}
            title={result[`title-${locale}`]}
          >
            {result[`title-${locale}`]}
          </LocalizedLink>
          <span className="tag ml-2"><FormattedMessage id={"general.page"} /></span>
        </h2>
        {result[`content-${locale}`] && (<p>
          {result[`content-${locale}`].substr(0, SEARCH_RESULT_EXCERPT_LENGTH)}{" "}
          […]
        </p>)}
      </div>
    </div>
  )
}

const SearchResultPost = ({ result, locale }) => {
  console.log(result)
  return (
    <div className="columns is-mobile mb-4">
      <div className="column is-one-quarter">
        {result.images[0] && (
          <LocalizedLink
            to={`/${result[`slug-${locale}`]}`}
            alt={result[`title-${locale}`]}
            title={result[`title-${locale}`]}
          >
            <GatsbyImage
              objectFit="contain"
              loading={"lazy"}
              alt={result.images[0].altText || result[`title-${locale}`]}
              image={result.images[0].gatsbyImageData}
            />
          </LocalizedLink>
        )}
      </div>
      <div className="column">
        <h2 className="subtitle">
          <LocalizedLink
            to={`/${result[`slug-${locale}`]}`}
            alt={result[`title-${locale}`]}
            title={result[`title-${locale}`]}
          >
            {result[`title-${locale}`]}
          </LocalizedLink>
          <span className="tag ml-2"><FormattedMessage id={"general.post"} /></span>
        </h2>
        {result[`content-${locale}`] && (<p>
          {result[`content-${locale}`].substr(0, SEARCH_RESULT_EXCERPT_LENGTH)}{" "}
          […]
        </p>)}
      </div>
    </div>
  )
}

const SearchResultProduct = ({ result, locale }) => {
  return (
    <div className="columns is-mobile mb-4">
      <div className="column is-one-quarter">
        {result.images[0] && (
          <LocalizedLink
            to={`/product/${result[`slug-${locale}`]}`}
            alt={result[`title-${locale}`]}
            title={result[`title-${locale}`]}
          >
            <GatsbyImage
              objectFit="contain"
              loading={"lazy"}
              alt={result.images[0].altText || result[`title-${locale}`]}
              image={result.images[0].gatsbyImageData}
            />
          </LocalizedLink>
        )}
      </div>
      <div className="column">
        <h2 className="subtitle">
          <LocalizedLink
            to={`/product/${result[`slug-${locale}`]}`}
            alt={result[`title-${locale}`]}
            title={result[`title-${locale}`]}
          >
            {result[`title-${locale}`]}
          </LocalizedLink>
          <span className="tag ml-2"><FormattedMessage id={"general.product"} /></span>
        </h2>
        {result[`description-${locale}`] && (<p>
          {result[`description-${locale}`].substr(
            0,
            SEARCH_RESULT_EXCERPT_LENGTH
          )}{" "}
          […]
        </p>)}
      </div>
    </div>
  )
}

const SearchResult = ({ result, locale, type = SEARCH_RESULT_TYPE_PAGE }) => {
  if (!result) return <React.Fragment></React.Fragment>

  switch (type) {
    case SEARCH_RESULT_TYPE_PRODUCT:
      return <SearchResultProduct result={result} locale={locale} />
    case SEARCH_RESULT_TYPE_POST:
      return <SearchResultPost result={result} locale={locale} />
    default:
      return <SearchResultPage result={result} locale={locale} />
  }
}

export default SearchResult
