import React from "react"
import { useIntl } from "react-intl"

const SearchBar = ({ searchQuery, setSearchQuery }) => {
  // hooks
  const { formatMessage } = useIntl()

  // localize form action attribute
  const formAction = formatMessage({ id: "routes.search" })

  // used `defaultValue` as recommended here: https://stackoverflow.com/a/56242886
  return (
    <form action={formAction} method="get" autoComplete="off">
      <div className="field">
        <div className="control">
          <input
            className="input"
            type="search"
            placeholder={formatMessage({ id: "general.search.placeholder" })}
            defaultValue={searchQuery}
            onInput={e => setSearchQuery(e.target.value)}
            name="s"
            autoFocus
            onFocus={e => e.currentTarget.select()}
          />
        </div>
      </div>
    </form>
  )
}

export default SearchBar
